<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Busqueda Avanzada</a></li>
      <li class="breadcrumb-item active">Inspeccion</li>
    </ol>
    <h1 class="page-header">Lista Inspecciones <small></small></h1>
    <panel title="">
      <div class="row">
        <div class="col-md-4 mb-3">
          <label class="form-label" id="numeroInforme"><strong>Número Informe</strong> </label>
          <input class="form-control" type="text" v-model="form.numeroInforme"/>
        </div>
        <div class="col-md-4 mb-3">
          <label for="transportista-select" class="form-label"><strong>Transportista</strong></label>
          <v-select id="transportista-select"
                    label="razonSocial"
                    :options="empresasTipoPorEmpresa"
                    :reduce="transportista => transportista.id"
                    v-model="form.transportista"
          >
          </v-select>
        </div>
        <div class="col-md-4 mb-3">
          <label for="solicitante-select" class="form-label"><strong>Solicitante</strong></label>
          <v-select id="solicitante-select"
                    label="nombre"
                    :options="solicitanteList"
                    :reduce="solicitante => solicitante.id"
                    v-model="form.solicitante">
          </v-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 mb-3">
          <label for="lugar-select" class="form-label"><strong>Lugar Inspección</strong></label>
          <v-select id="lugar-select"
                    label="nombre"
                    :options="ubicaciones"
                    :reduce="lugar => lugar.id"
                    v-model="form.lugar">
          </v-select>
        </div>
        <div class="col-md-4 mb-3">
          <label for="equipo-select" class="form-label"><strong>Equipo</strong></label>
          <v-select id="equipo-select"
                    label="patente"
                    :options="equipoList"
                    :reduce="equipo => equipo.id"
                    v-model="form.equipo">
          </v-select>
        </div>
        <div class="col-md-4 mb-3">
          <label for="inspector-select" class="form-label"><strong>Inspector</strong></label>
          <v-select id="inspector-select"
                    label="nombre"
                    :options="inspectorList"
                    :reduce="inspector => inspector.id"
                    v-model="form.inspector">
          </v-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 mb-3">
          <label for="tipoEquipamiento-select" class="form-label"><strong>Tipo Equipamiento</strong></label>
          <v-select id="tipoEquipamiento-select"
                    label="nombre"
                    :options="equipoTipoEquipamiento"
                    :reduce="tipoEquipamiento => tipoEquipamiento.id"
                    v-model="form.tipoEquipamiento">
          </v-select>
        </div>
        <div class="col-md-4 mb-3">
          <label for="estadoInspeccion-select" class="form-label"><strong>Estado Inspección</strong></label>
          <v-select id="estadoInspeccion-select"
                    label="nombre"
                    :options="estadoInspeccion"
                    :reduce="estado => estado.id"
                    v-model="form.estado">
          </v-select>
        </div>
        <div class="col-md-4 mb-3">
          <label for="periodo-select" class="form-label"><strong>Periodo</strong></label>
          <v-select id="periodo-select"
                    label="text"
                    :options="periodOptions"
                    :reduce="option => option.value"
                    v-model="selectedPeriod">
          </v-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 mb-3">
          <label for="tracto-select" class="form-label"><strong>Tracto</strong></label>
          <v-select id="tracto-select"
                    label="patente"
                    :options="tractoList"
                    :reduce="tracto => tracto.id"
                    v-model="form.tracto">
          </v-select>
        </div>
        <div class="col-md-4 mb-3">
          <label for="conductor-select" class="form-label"><strong>Conductor</strong></label>
          <v-select id="conductor-select"
                    label="nombre"
                    :options="sortedConductorList"
                    :reduce="conductor => conductor.id"
                    v-model="form.conductor">
          </v-select>
        </div>
        <div class="col-md-4 mb-3">
          <label for="tipoInspeccionOptions-select" class="form-label"><strong>Tipo Inspeccion</strong></label>
          <v-select id="tipoInspeccionOptions-select"
                    label="text"
                    :options="tipoInspeccionOptions"
                    :reduce="option => option.value"
                    v-model="form.inspecciones">
          </v-select>
        </div>
      </div>
      <div class="button-container mb-4 mt-4" style="display: flex; justify-content: center; align-items: center; gap: 10px">
        <button class="btn btn-warning" @click="buscarInspeccionAbierta">Buscar Inspecciones Abiertas</button>
        <button class="btn btn-green" @click="buscar">Buscar</button>
        <button class="btn btn-primary" @click="limpiarFormulario">Limpiar</button>
      </div>
      <div class="row">
      </div>
      <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{ enabled: true, placeholder: 'Buscar' }"
          :pagination-options="{ enabled: true,  position: 'bottom' }"
          :selectOptions="{
					selectOnCheckboxOnly: true,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
					}"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'id'">
            <!-- Usar una expresión ternaria para decidir la ruta -->
            <router-link :to="props.row.tipoInspeccion ? '/showInspeccion/' + props.row.id : '/agendamiento/showInspeccionProgramada/' + props.row.id" tag="span">
              <span style="font-weight: bold; color: #5aabd1; text-decoration: underline;">
                {{ props.row.id }}
              </span>
            </router-link>
          </span>
          <span v-else-if="props.column.field === 'fechaDeInspeccionSolicitada'">
            {{ formatearFecha(props.row.fechaDeInspeccionSolicitada)}}
          </span>
          <span v-else-if="props.column.field === 'estado'">
        <span :class="{'text-aprobado': props.row.estado === 'APROBADO', 'text-rechazado': props.row.estado === 'RECHAZADO', 'text-aprobado-obs': props.row.estado === 'APROBADO CON OBSERVACIONES'}">
          {{ props.row.estado }}
        </span>
      </span>
        </template>
      </vue-good-table>
    </panel>
  </div>
</template>
<script>
import axios from "axios";
import usuarioInspector from "@/pages/UsuarioInspector.vue";

export default {
  name: "ListaInspeccionesFiltradas",
  computed: {
    usuarioInspector() {
      return usuarioInspector
    },
    sortedConductorList() {
      return this.conductorList.slice().sort((a, b) => {
        return a.nombre.localeCompare(b.nombre);
      });
    }
  },
  data() {
    return {
      realizarBusquedaInicial: true,
      empresasTipoPorEmpresa: [],
      ubicaciones: [],
      equipoList: [],
      conductorList: [],
      tractoList: [],
      equipoTipoEquipamiento: [],
      estadoInspeccion: [],
      inspectores: [],
      solicitanteList: [],
      inspectorList: [],
      selectedPeriod: 'Todo', // Valor por defecto seleccionado
      periodOptions: [
        {text: 'Todo', value: 'Todo'},
        {text: 'Hoy', value: 'Hoy'},
        {text: 'Ayer', value: 'Ayer'},
        {text: 'Esta semana', value: 'Esta semana'},
        {text: 'La semana pasada', value: 'La semana pasada'},
        {text: 'Este mes', value: 'Este mes'},
        {text: 'El mes pasado', value: 'El mes pasado'},
        {text: 'Este año', value: 'Este año'}
      ],
      tipoInspeccionOptions: [
        {text: 'Diarias', value: 'Diarias'},
        {text: 'Programadas', value: 'Programadas'}
      ],
      form: {
        transportista: null,
        numeroInforme: "",
        lugar: null,
        equipo: "",
        tracto: "",
        tipoEquipamiento: "",
        estado: "",
        inspector: "",
        solicitante: "",
        conductor: "",
        inspecciones: "",

      },
      columns: [{
        label: 'N°',
        field: 'id',
        width: '10%',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap'
      }, {
        label: 'Equipo',
        field: 'equipo',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },{
        label: 'Tracto',
        field: 'tracto',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      }, {
        label: 'Transportista',
        field: 'empresaDeTransporte',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },
        /* {
         label: 'Propietario',
         field: 'propietarioDelEquipo',
         thClass: 'text-nowrap',
         tdClass: 'text-nowrap'
       },  */
        {
          label: 'Fecha Inspección',
          field: 'fechaDeInspeccionSolicitada',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        }, {
          label: 'Lugar',
          field: 'lugarDeInspeccion',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        }, {
          label: 'Estado',
          field: 'estado',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        }, {
          label: 'Informe',
          field: 'numeroInforme',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
      ],
      rows: [
        {}
      ],
      listInspeccion: [],
    }
  },
  methods: {
    convertirColorRGBA(color, opacidad) {
      const colores = {
        red: '238 , 105, 105',
        green: '46, 204, 29',
        orange: '255, 165, 0',
        yellow: '255, 255, 0',
        blue: '16, 34, 231', // Color blue agregado
        lightgrey: '211, 211, 211', // Color lightgrey agregado
        white: '255, 255, 255'
        // Agrega más colores según sea necesario
      };
      return `rgba(${colores[color] || '0, 0, 0'}, ${opacidad})`; // color negro por defecto
    },
    async fetchData(url, params = {}) {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      // Construir la URL con parámetros
      const query = new URLSearchParams(params).toString();
      const finalUrl = `${url}?${query}`;

      try {
        const response = await axios.get(finalUrl, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data;
      } catch (error) {
        console.error(error);
        return []; // Devolver un array vacío en caso de error
      }
    },
    formatearFecha(fechaISO) {
      // Primero, verifica si la entrada es nula o no está definida.
      if (!fechaISO) {
        return ''; // Retorna una cadena vacía si no hay entrada.
      }

      const fecha = new Date(fechaISO);

      // Verifica si la fecha es válida
      if (isNaN(fecha.getTime())) {
        return ''; // Retorna una cadena vacía si la fecha es inválida.
      }

      // Si la fecha es válida, formatea y retorna la fecha en el formato deseado.
      return fecha.toLocaleDateString('es-CL', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    },
    async buscar() {
      const filtro = {
        numeroInforme: this.form.numeroInforme,
        transportista: this.form.transportista,
        solicitante: this.form.solicitante,
        lugar: this.form.lugar,
        equipo: this.form.equipo,
        tracto: this.form.tracto,
        inspector: this.form.inspector,
        tipoEquipamiento: this.form.tipoEquipamiento,
        estado: this.form.estado,
        periodo: this.selectedPeriod,
        conductor: this.form.conductor,
        inspecciones: this.form.inspecciones
      };

      await this.enviarFiltros(filtro);
    },
    async buscarInspeccionAbierta() {
      const filtro = {
        inspeccionesNoCerradas: true,
      };

      await this.enviarFiltros(filtro);
    },
    async enviarFiltros(filtro) {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      const username = obtenerToken.username; // Obtiene el username

      // Asegúrate de incluir el username en el objeto de filtro
      filtro.username = username;

      try {
        const response = await axios.post(`${process.env.VUE_APP_IP_BACKEND}/inspeccion/busquedaAvanzada`, filtro, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        this.rows = response.data;
        console.log(this.rows)
      } catch (error) {
        console.error("Error al buscar:", error);
      }
    },
    limpiarFormulario() {
      this.form = {
        numeroInforme: '',
        transportista: '',
        solicitante: '',
        lugar: '',
        equipo: '',
        tracto: '',
        conductor: '',
        inspector: '',
        tipoEquipamiento: '',
        estado: '',
        periodo: 'Todo',
        inspecciones: ''
      };
    },
    asignarData() {
      this.rows = this.listInspeccion;
    },
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    convertirFecha(fechaIso) {
      const fecha = new Date(fechaIso);
      return fecha.toISOString().split('T')[0]; // Esto devuelve la fecha en formato 'YYYY-MM-DD'
    },
  },

  async mounted() {
    try {

      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const username = obtenerToken.username;
      this.empresasTipoPorEmpresa = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/empresa/empresaTransportista', {username: username});
      this.ubicaciones = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/ubicacion/listaUbicacion');
      this.equipoList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/listaEquipo', {username: username});
      this.tractoList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/listaTracto', {username: username});
      this.equipoTipoEquipamiento = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/tipoEquipamientoList');
      this.estadoInspeccion = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/formulario/estadoInspeccionList');
      this.inspectores = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/user/usuarioInspector');
      this.solicitanteList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/user/listSolicitantes', {username: username});
      this.conductorList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/user/listaConductorBusquedaAvanzada', {username: username});
      this.inspectorList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/user/listaInspectoresBusqueda', {username: username});

      // Ordenar la lista de conductores
      this.conductorList.sort((a, b) => a.nombre.localeCompare(b.nombre));

      const queryParams = this.$route.query;
      if (Object.keys(queryParams).length > 0) {
        this.realizarBusquedaInicial = false; // No realizar la búsqueda inicial
        this.form.equipo = queryParams.equipo || this.form.equipo;
        this.form.tracto = queryParams.tracto || this.form.tracto;
        this.form.estado = queryParams.estado || this.form.estado;
        this.form.conductor = queryParams.conductor || this.form.conductor;
        this.form.inspecciones = queryParams.inspecciones || this.form.inspecciones;

        // Ejecutar la búsqueda después de asignar los parámetros de consulta
        await this.buscar();
      }
      if (this.realizarBusquedaInicial) {
        await this.buscar();
      }

    } catch (error) {
      console.error(error);
    }
  },
  created() {
    this.$validarLogin()
  }
}
</script>

<style scoped>
.select-container {
  position: relative;
}
.select-container::after {
  content: '\25BC'; /* Puedes cambiar esto por un ícono personalizado como una imagen de fondo */
  position: absolute;
  pointer-events: none;
  top: 50%; /* Centra el elemento verticalmente */
  right: 10px; /* Distancia desde el borde derecho del contenedor */
  transform: translateY(-50%);
  color: #333;
  font-size: 16px; /* Tamaño de la flecha */
}
.text-aprobado {
  color: #2ecc1d;
  font-weight: bold;
}
.text-rechazado {
  color: rgba(238, 105, 105, 0.9);
  font-weight: bold;
}
.text-aprobado-obs {
  color: #2ecc1d;
  font-weight: bold;
}

</style>
